import { render, staticRenderFns } from "./CalenderEventItem.vue?vue&type=template&id=0a78b6a6&scoped=true&"
import script from "./CalenderEventItem.vue?vue&type=script&lang=ts&"
export * from "./CalenderEventItem.vue?vue&type=script&lang=ts&"
import style0 from "./CalenderEventItem.less?vue&type=style&index=0&id=0a78b6a6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a78b6a6",
  null
  
)

export default component.exports