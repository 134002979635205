








































































































































































































































































































































































































































































































































































































































import Events from "@/components/plannerComponent/components/Events/Events";
export default Events;
